.header{
    height: 100dvh;
    width: 100vw;
    background-color: var(--pcolor);
    margin-right: -5vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.header .content, .header .bubble{
    width: 50%;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header .content p{
    padding: 3rem 0 4rem;
    color: white;
    font-size: 24px;
    font-weight: 300;
}

.resumebtn{
    position: relative;
    margin-right: 30px;
    font-weight: bold;
    font-size: 18px;
    
}

.resumebtn::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -8px;
    width: 0;
    height: 1px;
    background-color: white;
    transition: width 0.3s ease;
}
  
.resumebtn:hover::after {
    width: 100%;
}

.svg{
    display: flex;
    align-items: center;
    font-size: 20px;
    color: white;
    position: absolute;
    bottom: 8vh;
    padding: 15px 8px;
    border: 1px solid white;
    border-radius: 50px;
    animation: scroll 1.5s infinite;
}
@keyframes scroll {
    0% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(10px);
    }
    60% {
      transform: translateY(0);
    }
  }

  /* ✅ انیمیشن اسلاید از پایین به بالا برای content */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ✅ انیمیشن اسلاید از بالا به پایین برای bubble */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.content {
  animation: slideUp 1s ease-out forwards;
}

.bubble {
  animation: slideDown 1s ease-out forwards;
}



@media (max-width: 768px) {
  .header .content{
    left: 50%;
    width: 80%;
  }

  .bubble{
      display: none !important;
  }

  .content{
      text-align: center;
  }

  .content h1{
      font-size: 32px;
  }

  .content p{
      font-size: 20px !important;
  }
}