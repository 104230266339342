.posts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5dvh;
  justify-content: space-between;
  width: 100vw;
  padding: 2vw 10vw;
  margin-right: -5vw;
}

.post {
  width: calc(40vw - 1vw);
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(242, 245, 248, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
  box-shadow: 0 13px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  transition: background-color 0.5s, box-shadow 0.5s;
  display: flex;
}

.post:hover {
  background-color: #ffff;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s, box-shadow 0.5s;
}

.post-image {
  width: 200px;
  height: 220px;
  margin: 15px;
  border-radius: 10px 10px 10px;
}

.post-content {
  width: calc(100% - 200px);
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow: hidden;
}

.post-info {
  background-color: white;
  display: flex;
  font-size: 14px;
  color: black;
  margin-top: 10px;
  height: 60px;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
}

.post-info .info-details {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.post-info .category {
  background: linear-gradient(
    90deg,
    rgb(255, 255, 255, 1) 0%,
    rgba(236, 236, 237, 0.8) 50%,
    rgb(255, 255, 255, 1) 100%
  );
  height: 100%;
  padding: 0 30px;
  display: flex;
  align-items: center;
}

/* skeleton */

.skeleton {
  background: #e0e0e0;
  gap: 10px;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton-image {
  background: #d0d0d0;
}

.skeleton-title {
  height: 20px;
  background: #c0c0c0;
  border-radius: 5px;
}

.skeleton-text {
  width: 100%;
  height: 15px;
  background: #c0c0c0;
  border-radius: 5px;
}

.skeleton-text.small {
  width: 60%;
}

.skeleton-info {
  width: 30%;
  height: 20px;
  background: #c0c0c0;
  border-radius: 5px;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

@media (max-width: 768px) {
  .posts-container {
    padding: 3dvh 5vw;
  }
  .post-image {
    width: 100px;
    height: 110px;
    margin: 5px;
    border-radius: 10px 10px 10px;
  }
  .post-content {
    width: 100%;
  }
  .post-content h3 {
    font-size: 16px;
  }
  .post-content p {
    display: none;
  }
  .post-info {
    margin: 0;
    height: auto;
  }

  .post-info .info-details {
    height: 35px;
    display: none;
  }
  .post-info .category,
  .post-info .post-date {
    background: transparent;
    background-color: transparent;
    display: initial;
    padding: 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px) {

}

@media (max-width: 1440px) {
  .post {
    width: calc(100vw - 1vw);
  }
}

@media (min-width: 480px) {
  .post-info .comments {
    background: transparent;
    background-color: transparent;
    display: initial;
    padding: 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
