@import url("https://fonts.googleapis.com/css2?family=Leckerli+One&family=Vazirmatn:wght@100..900&display=swap");

:root {
  --pcolor: #20232a;
  --seccolor: #202328;
  --redcolor: #d32f2f;
  --descriptioncolor: #777990;
  --textcolor: #333333;
  --boldtextcolor: black;
  --textsize: 16px;
  --primaryradius: 8px;

  --navbar-height: 80px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Vazirmatn", sans-serif;
}

body {
  background: white;
  direction: rtl;
  width: 90%;
  margin: 0 auto;
  overflow-x: hidden;
}

h1 {
  color: #fff;
  text-decoration: none;
  font-size: 36px;
  font-weight: bold;
}

h2 {
  color: var(--seccolor);
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
}

h4,
a {
  color: #fff;
  text-decoration: none;
}

p {
  color: var(--textcolor);
}

ul {
  list-style-type: none;
}

.btn {
  /* background: rgb(238, 0, 0); */
  color: white;
  padding: 10px 20px 10px 20px;
  transition: 0.3s;
  border-radius: 50px;
  font-weight: bold;
  font-size: 18px;
  box-shadow: inset 0 0 0 1px white;
}
.btncolor{
  color: white;
  background: var(--pcolor);
  font-size: 16px;
  box-shadow: 0px 0px 3px 1px var(--pcolor);
}
.btn:hover {
  background: var(--redcolor);
  box-shadow: none;
  box-shadow: 0px 0px 0px 0px var(--pcolor);
}



@media screen and (max-width: 768px) {
  h1 {
    font-size: 2.5em;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 1440px) {
}
