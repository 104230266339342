.pdfviewer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3dvh;}
.pdf{
    margin: 3dvh 0;
    border-radius: var(--primaryradius);
    box-shadow: 0px 0px 3px 1px var(--pcolor);
    
}