.footer{
    width: 100vw;
    margin-right: -5vw;
    padding: 6rem;
    background-color: var(--pcolor);
}

.footer-container{
    max-width: 1140px;
    margin: auto;
    display: flex;
    transition: 0.8s;
}

.texticon{
    display: flex;
    padding: 5px 0;
}

.right, .left{
    width: 46%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
}

.texticon svg{
    margin-left: 2rem;
}

.right h4{
    padding: 10px 0;
}

.socials svg{
    margin: 0 5px;
    font-size: 26px;
}

.footer p{
    color: white;
}

@media screen and (max-width: 640px) {

    .footer{
        padding: 2rem;

    }

    .footer-container{
        flex-direction: column;

    }
    
    .right, .left{
        width: 100%;
    }
}