.expertises {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-margin-top: var(--navbar-height);
}

.expertises p {
  width: 20%;
  text-align: center;
  font-size: 16px;
  color: var(--descriptioncolor);
}

.expertises .container {
  padding: 50px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
  width: 80%;
}

.expertises .container div {
  width: 300px;
  height: 300px;
  background-color: #e3e3e4;
  border-radius: var(--primaryradius);
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
}

.expertises .container div svg {
  font-size: 50px;
  color: var(--redcolor);
}

.expertises .container div p {
  width: 100%;
}
.expertises .container div a {
  position: relative;
  bottom: 0;
  color: var(--redcolor);
}

@media (max-width: 768px) {
  .expertises .container div {
    width: 90%;
  }

  .expertises p {
    width: 80%;
  }
}

@media (max-width: 1024px) {
}

@media (max-width: 1440px) {
}
