.skills {
width: 100vw;
height: 100vh;
background: #262626;
display: flex;
justify-content: center;
align-items: center;
direction: ltr;

}

.bars {
padding: 10px;
width: 500px;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
}

.skill {
width: 100%;
margin: 10px 0;
}

.skilltitle {
color: #fff;
font-weight: 600;
font-size: 1.5rem;
}

.progress-bar {
background: #404040;
border-radius: 3px;
width: 100%;
height: 15px;
margin-top: 5px;
  /* overflow: hidden; */
}

.progress {
/*   width: 0; */
}

#htmlcss .progress {
max-width: 91%;
height: 100%;
background: #ed2828;
border-radius: 3px;
position: relative;
animation: 1s ease 0s 1 loadBars; 
}

#htmlcss .progress::after {
content: "83%";
font-size: 12px;
line-height: 14px;
text-align: center;
height: 15px;
width: 30px;
background: #fff;
position: absolute;
padding: 2px 3px;
border-radius: 4px;
right: -18px;
top: -30px;
}

#htmlcss .progress::before {
content: "";
position: absolute;
width: 0; 
height: 0; 
right: -5px;
top: -12px;  
border-left: 6px solid transparent;
border-right: 6px solid transparent;
border-top: 6px solid #fff;
}

#wordpress .progress {
max-width: 92%;
height: 100%;
background: #ed2828;
position: relative;
animation: 1s ease 0s 1 loadBars; 
}

#wordpress .progress::after {
content: "92%";
font-size: 12px;
line-height: 14px;
text-align: center;
height: 15px;
width: 30px;
background: #fff;
position: absolute;
padding: 2px 3px;
border-radius: 4px;
right: -18px;
top: -30px;
}

#wordpress .progress::before {
content: "";
position: absolute;
width: 0; 
height: 0; 
right: -5px;
top: -12px;  
border-left: 6px solid transparent;
border-right: 6px solid transparent;
border-top: 6px solid #fff;
}

#js .progress {
max-width: 60%;
height: 100%;
background: #ed2828;
position: relative;
animation: 1s ease 0s 1 loadBars; 
}

#js .progress::after {
content: "60%";
font-size: 12px;
line-height: 14px;
text-align: center;
height: 15px;
width: 30px;
background: #fff;
position: absolute;
padding: 2px 3px;
border-radius: 4px;
right: -18px;
top: -30px;
}

#js .progress::before {
content: "";
position: absolute;
width: 0; 
height: 0; 
right: -5px;
top: -12px;  
border-left: 6px solid transparent;
border-right: 6px solid transparent;
border-top: 6px solid #fff;
}

@keyframes loadBars{
0% {
    width: 0;
}
100% {
    width: 100%;
}

}