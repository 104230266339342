.projhead{
}
.headermask{
    height: 40vh;
    width: 100vw;
    margin-right: -5vw;
}

.headerimg2{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.contents{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 20vh;
    left: 50vw;
    text-align: center;
    transition: 0.3s;
}

@media screen and (max-width: 700px) {

}

