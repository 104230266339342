.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: var(--navbar-height);
    z-index: 10;
    padding: 0 10vw;
}

.navbar-bg{
    background-color: rgba(255, 255, 255, 0.95);
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 5%);

}

.navbar svg{
    color: white;
    font-size: 26px;

}

.navbar-bg a, .navbar-bg svg{
    color: var(--pcolor) !important;
    transition: 0.3s;
}



.nav-menu{
    display: flex;
}

.nav-menu li{
    padding: 0 1rem;
}

.nav-menu li a{
    font-size: 1.2rem;
    font-weight: 500;
    transition: 0.3s;
}

.navbar a.active, .navbar-bg a.active{
    color: var(--redcolor)!important;
}

.navbar a:hover, .navbar-bg a:hover, .navbar svg:hover, .navbar-bg svg:hover{
    text-shadow: 0px 0px 1px var(--descriptioncolor);
    color: var(--redcolor)!important;
    transition: 0.3s;
}

.hamburger {
    display: none;
}

.navbar a span{
    font-family: "Leckerli One", serif;
    font-size: 1.8em;
    font-weight: 400;
}


.headsocials a{
    margin: 0 8px;
}

.nav-menu div{
    display: none;
}
.nav-menu.active div svg{
    display: none;
}



@media (max-width: 768px) {
    
}

@media screen and (max-width: 1024px) {
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100dvh;
        background: #101214fa;
        position: absolute;
        color: green;
        top: 0;
        transform: translateX(-100vw);
        transition: transform 0.3s ease-in-out;
        z-index: -1;
    }

    body.menu-open {
        overflow: hidden !important;
    }    

    .nav-menu.active {
        transform: translateX(10vw);
    }

    .nav-menu div{
        display: initial;
    }
    .nav-menu.active div{
        position: absolute;
        bottom: 6dvh;
    }

    .nav-menu.active div svg{
        display: initial;
        font-size: 30px;
        margin: 0 10px;
    }



     .nav-menu.active a, .nav-menu.active svg{
        color: white !important;
        transition: 0.3s;
    }

    .nav-menu li{
        padding: 1.1rem 0;
    }

    .nav-menu li a{
        color: white;
        font-size: 1.5rem;
    }

    .hamburger {
        display: initial;
    }
    
    .headsocials{
        display: none;
    }

    .menu-open.navbar-bg a, 
    .menu-open.navbar-bg svg {
        color: white !important;
    }

    .navbar a.active, .navbar-bg a.active{
        color: var(--redcolor)!important;
    }
}

@media (max-width: 1440px) {

  }